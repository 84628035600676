@font-face {
    font-family: 'AvenirLTStd-Black';
    src: url(../font/AvenirLTProBlack.woff) format("woff");
}
@font-face {
    font-family: 'AvenirLTStd-Book';
    src: url(../font/AvenirLTProBook.woff) format("woff");
}
@font-face {
    font-family: 'AvenirLTStd-Roman';
    src: url(../font/AvenirLTProRoman.woff) format("woff");
}
@font-face {
    font-family: 'TrajanPro-Bold';
    font-style: normal;
    font-weight: 700;
    src: url(../font/TrajanPro-Bold.woff) format("woff");
}
@font-face {
    font-family: 'TrajanPro-Regular';
    font-style: normal;
    font-weight: 400;
    src: url(../font/TrajanPro-Regular.woff) format("woff");
}

:root{
    --font-AvenirLTStd-Black: 'AvenirLTStd-Black', AvenirLTStd, sans-serif;
    --font-AvenirLTStd-Book: 'AvenirLTStd-Book', AvenirLTStd, sans-serif;
    --font-AvenirLTStd-Roman: 'AvenirLTStd-Roman', AvenirLTStd, sans-serif;
    --font-TrajanPro-Bold: 'TrajanPro-Bold', TrajanPro, sans-serif;
    --font-TrajanPro-Regular: 'TrajanPro-Regular', TrajanPro, sans-serif;
}