.detail-area-360view{
    position: relative;
    width: 100%;
    overflow: hidden;
    .videodokumtasi{
        width: 100% !important;
        height: 100% !important;
        video{
            border-radius: 10px;
        }
    }
}