.visual-area{
    position: relative;
    overflow: hidden;
    .video-frame{
        position: relative;
        > div{
            margin: 0 auto;
            position: relative;
            width: 100% !important;
            height: 304px !important;
            video{
                &:hover, &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
}
.visual-desc{
    position: relative;
    overflow: hidden;
    font-family: var(--font-AvenirLTStd-Book);
    .list-desc{
        position: relative;
        > div{
            text-align: center;
            p{
                margin: 20px 0;
                font-size: 20px;
                font-family: var(--font-AvenirLTStd-Book);
            }
            span{
                font-family: var(--font-AvenirLTStd-Book);
                font-weight: 600;
                color: var(--Loblolly);
                font-size: 18px;
            }
        }
    }
}
.stategic-area{
    position: relative;
    overflow: hidden;
    font-family: var(--font-AvenirLTStd-Book);
    .list-strategic-location{
        @media (min-width: 992px){
            -moz-column-count: 2;
            -moz-column-gap: 10px;
            -webkit-column-count: 2;
            -webkit-column-gap: 10px;
            column-count: 2;
            column-gap: 10px;
        }
        ul{
            li{
                display: flex;
                align-items: flex-start;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
                span{
                    margin-left: 20px;
                }
            }
        }
    }
}
.simulasi-area{
    position: relative;
    overflow: hidden;
    font-family: var(--font-AvenirLTStd-Book);
    form{
        .form-group{
            .form-label{
                padding-left: 0;
            }
            .form-control{
                padding: 0 1rem;
                border-radius: 0;
                border: 1px solid var(--Cement);
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .btn-simulasi{
            padding: .5rem 1.5rem;
            color: var(--White);
            border-radius: 0;
            background-color: var(--Cement);
            border: none;
            &:focus{
                outline: none;
                box-shadow: none;
            }
            &:hover{
                background-color: var(--Loblolly);
            }
        }
        .send-data{
            display: block;
        }
    }
}
.other-unit-area{
    position: relative;
    overflow: hidden;
    font-family: var(--font-AvenirLTStd-Book);
    .section-body{
        .heading-other-unit{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            p{
                width: auto;
                &:last-child{
                    height: 1px;
                    width: 91.9%;
                    display: inline-block;
                    border-radius: 5px;
                    bottom: 26px;
                    // position: absolute;
                    margin: 5px 0 5px 20px;
                    background-color: var(--Cement);
                }
            }
        }
        .owl-carousel{
            .owl-item{
                .item{
                    text-align: center;
                    margin-bottom: 20px;
                    img{
                        margin-bottom: 20px;
                    }
                    h3{
                        font-size: 16px;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                        font-family: var(--font-TrajanPro-Regular);
                    }
                    a{
                        padding: .5rem 1rem;
                        margin-bottom: 10px;
                        border-radius: 0;
                        color: var(--White);
                        background-color: var(--Cement);
                        &:hover{
                            background-color: var(--Loblolly);
                        }
                    }
                }
            }
        }
    }
}