:root{
    --White: #FFFFFF;
    --White50: #FFFFFF50;
    --White90: #FFFFFF90;
    --White00: #FFFFFF00;
    --Black: #000000;
    --Black80: #00000080;
    --Black50: #00000050;
    --Cement: #856D5B;
    --Sandal: #A6846E;
    --SandDune: #816E5F;
    --SandDune30: #816E5F30;
    --Loblolly: #BEC7D0;
    --Loblolly50: #BEC7D050;
    --PineCone: #715F52;
}