.home-hero-area{
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .owl-item{
        .item{
            margin: 0;
            position: relative;
            width: 100%;
            height: 100vh;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100vh;
                background-color: var(--Black50);
            }
        }
    }
    .owl-dots {
        text-align: left;
        position: absolute;
        bottom: 20px;
        margin: 20px 50px;
        z-index: 999;
        .owl-dot{
            span{
                width: 15px;
                height: 15px;
                background: transparent;
                border: 1px solid var(--White);
            }
            &.active{
                span{
                    background: var(--White);
                }
            }
        }
    }
    .overlay{
        position: absolute;
        width: 100%;
        z-index: 10;
        background-color: transparent;
        .hero-content {
            position: relative;
            width: 100%;
            overflow: hidden;
            h1 {
                font-size: 27px;
                color: var(--White);
                margin: 1rem 0;
                font-family: var(--font-AvenirLTStd-Book);
            }
            p{
                font-size: 22px;
                font-weight: 400;
                color: var(--White);
                font-family: var(--font-AvenirLTStd-Roman);
            }
            .btn-hero{
                padding: .5rem 1rem;
                color: var(--White);
                font-family: var(--font-AvenirLTStd-Book);
                border: 1px solid var(--White);
                border-radius: 0;
                background-color: var(--Cement);
                text-transform: uppercase;
                &:hover{
                    background-color: var(--Loblolly);
                    color: var(--Cement);
                    outline: none;
                    box-shadow: none;
                }
                &:focus{
                    background-color: var(--Loblolly);
                    outline: none;
                    box-shadow: none;
                }   
            }
        }
    }
}
.home-about-area{
    position: relative;
    width: 100%;
    overflow-x: hidden;
    .section-body{
        position: relative;
        .title-body{
            font-family: var(--font-TrajanPro-Regular);
            font-size: 24px;
            margin-bottom: 30px;
            color: var(--Cement);
        }
        .content-body{
            font-size: 18px;
            font-family: var(--font-AvenirLTStd-Book);
        }
        .about-img{
            position: relative;
            > div{
                position: relative;
                width: 100% !important;
                height: 100% !important;
                video{
                    border-radius: 0;
                    &:hover, &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
.home-fasilitas-area{
    position: relative;
    width: 100%;
    overflow: hidden;
    .section-body{
        .title-body{
            margin-bottom: 30px;
        }
        .item{
            text-align: center;
            .icon-wrap{
                position: relative;
                margin-bottom: 20px;
                display: inline-block;
                svg{
                    width: 50px;
                    height: 50px;
                    fill: var(--SandDune);
                }
            }
            .item-title{
                color: var(--Cement);
                font-size: 20px !important;
                font-family: var(--font-AvenirLTStd-Book);
            }
        }
    }
}
.home-strategiclocation-area{
    position: relative;
    width: 100%;
    overflow-x: hidden;
    .section-body{
        .sl-box{
            position: relative;
            background-color: var(--White);
            margin: 12px 0;
            .sl-desc{
                position: relative;
                width: 100%;
                left: 0;
                bottom: 0;
                font-family: var(--font-AvenirLTStd-Book);
                padding: .5rem;
                text-align: center;
                p{
                    font-size: 12px;
                    color: var(--Cement);
                    font-family: var(--font-TrajanPro-Regular);
                }
            }
        }
    }
}
.home-stories-area{
    position: relative;
    background-color: var(--Loblolly);
    padding: 1rem 0;
    h3{
        font-size: 1.5rem;
        color: var(--Cement);
        font-family: var(--font-TrajanPro-Regular);
    }
    span{
        color: var(--White);
        font-size: 18px;
        font-family: var(--font-AvenirLTStd-Book);
    }
    p{
        color: var(--White);
        font-size: 16px;
        font-family: var(--font-AvenirLTStd-Book);
    }
}
.home-material-area{
    position: relative;
    overflow: hidden;
    .section-body{
        position: relative;
        .nav{
            display: flex;
            align-items: center;
            justify-content: center;
            &.nav-tabs{
                border-bottom: 0;
            }
        }
        .nav-tabs{
            .nav-link{
                color: var(--Black);
                font-size: 18px;
                font-family: var(--font-AvenirLTStd-Book);
                margin: 0 5px;
                &.active{
                    color: var(--White);
                    background-color: var(--Cement);
                    border-radius: 0;
                    border-color: transparent;
                }
                &:hover{
                    color: var(--White);
                    background-color: var(--Cement);
                    border-radius: 0;
                    border-color: transparent;
                }
            }
        }
        .tab-content{
            margin-top: 30px;
            .tab-pane{
                .row{
                    > div {
                        padding: 22px calc(var(--bs-gutter-x) * .5) 0 calc(var(--bs-gutter-x) * .5);
                    }
                }
                .material-box{
                    padding: 17px 12px;
                }
                .overlay-box{
                    padding: 8px 12px;
                    border: 1px solid var(--Alto);
                    border-radius: 20px;
                    position: relative;
                    -webkit-transition: all 0.5s ease;
                    transition: all 0.5s ease;
                }
            }
            .material-box {
                background-color: transparent;
                border-radius: 20px;
                padding: 35px;
                .material-content{
                    text-align: center;
                    .image-section{
                        position: relative;
                        .material-img{
                            width: 200px;
                            height: 200px;
                            margin: 0 auto;
                            border-radius: 100%;
                            position: relative;
                            z-index: 1;
                            background-color: var(--Loblolly);
                            > div{
                                img {
                                    border-radius: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                        .material-detail{
                            margin-top: 55px;
                            h3{
                                font-size: 18px;
                                font-weight: 400;
                                text-transform: none;
                                color: var(--Black);
                                font-family: var(--font-AvenirLTStd-Book);
                            }
                            h4{
                                font-family: var(--font-AvenirLTStd-Book);
                                font-size: 16px;
                                margin: 0;
                                font-weight: 400;
                                color: var(--Loblolly);
                            }
                        }
                    }
                }
            }
        }
    }
}
.home-galery-area{
    position: relative;
    .section-body{
        position: relative;
        margin-bottom: 20px;
        .gallery-box{
            position: relative;
            width: 100%;
            height: auto;
            justify-items: center;
            align-items: center;
            display: grid;
            overflow: hidden;
            img {
                -webkit-transform: scale(1);
                transform: scale(1);
                -webkit-transition: .3s ease-in-out;
                transition: .3s ease-in-out;
                object-fit: cover;
                width: 100%;
                height: 364px;
            }
            &:hover{
                img{
                    -webkit-transform: scale(1.3);
                    transform: scale(1.3);
                }
            }
        }
    }
    .more-gallery{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        font-family: var(--font-AvenirLTStd-Book);
        p{
            font-size: 14px;
            color: var(--Cement);
            width: auto;
            &:first-child{
                height: 1px;
                width: 87%;
                display: inline-block;
                border-radius: 5px;
                bottom: 26px;
                margin: 5px 20px 5px 0px;
                background-color: var(--Cement);
            }
        }
        a{
            padding: .5rem 1rem;
            border-radius: 0;
            color: var(--White);
            background-color: var(--Cement);
            &:hover{
                background-color: var(--Loblolly);
            }
        }
    }
}
.masterplan-area{
    position: relative;
    overflow: hidden;
    width: 100%;
    ul{
        flex-wrap:wrap;
        justify-content:center;
        li{
            margin: 10px 10px;
            img{
                width: 200px;
            }
        }
    }
}