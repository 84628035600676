@import './partials/base';

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

body {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    overflow-x: hidden;
    color: var(--Black);
    font-family: var(--font-roboto);

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--Loblolly);
    }
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 30px;
    right: 30px;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
    background: transparent;
    border: transparent;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}



.wrapper{
    position: relative;
}

.section-heading{
    text-align: center;
    margin-bottom: 100px;
    text-transform: uppercase;
}

section {
    margin: 100px 0;    
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

p,
span,
strong,
small,
ul,
li {
    padding: 0;
    margin: 0;
}

.my-100{
    margin-top: 100px;
    margin-bottom: 100px;
}

.section-heading {
    .title-heading {
        font-family: var(--font-TrajanPro-Regular);
        text-transform: uppercase;
        color: var(--Cement);
    }
}

.hero-area {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        background-color: var(--Black50);
    
        .hero-content {
            position: relative;
            width: 100%;
            overflow: hidden;
    
            p {
                font-size: 27px;
                color: var(--White);
                margin: 1rem 0;
                font-family: var(--font-TrajanPro-Regular);
            }
        }
    }
}

.hero-news-area, .hero-360view-area {
    position: relative;
    margin: 0;
    width: 100%;
    height: 400px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .overlay{
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        .hero-content {
            position: relative;
            width: 100%;
            overflow: hidden;
            p {
                font-size: 27px;
                color: var(--White);
                margin: 1rem 0;
                font-family: var(--font-TrajanPro-Regular);
            }
        }
    }
}

@media screen and (max-width: 961px) {
    h1{
        font-size: 30px !important;
    }
    h2{
        font-size: 26px !important;
    }
    h3{
        font-size: 24px !important;
    }
    h4{
        font-size: 20px !important;
    }
    h5{
        font-size: 18px !important;
    }
    h6{
        font-size: 16px !important;
    }
    section{
        margin: 50px 0 !important;
        &:first-child{
            margin-top: 0 !important;
        }
    }
    .mb-lg-30{
        margin-bottom: 30px!important;
    }
    .section-heading{
        margin: 50px 0 !important;
    }

    .contact-info{
        .contact-info__icon{
            width: 40px;
            height: 40px;
        }
    }
}

.masterplan-area{
    .section-heading{
        h2{
            &.title-heading{
                font-size: 1.5rem;
            }
        }
    }
}

.firstHeading{
    clip: rect(0 0 0 0)!important;
    border: 0!important;
    height: 0.0625rem!important;
    margin: -0.0625rem!important;
    overflow: hidden!important;
    padding: 0!important;
    position: absolute!important;
    width: 0.0625rem!important;
}