footer{
    position: relative;
    width: 100%;
    overflow: hidden;
    font-family: var(--AvenirLTStd-Book);
    .footer-top{
        padding: 80px 0;
        margin: 0 !important;
        background-color: var(--Loblolly);
        font-family: var(--font-TrajanPro-Regular);
        .footer-about{
            font-family: var(--font-AvenirLTStd-Book);
            img{
                margin-bottom: 20px;
            }
            p{
                color: var(--PineCone);
            }
        }
        .footer-sosial-media{
            li{
                a{
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    color: var(--PineCone);
                    @media screen and (max-width: 961px) {
                        justify-content: center;
                    }
                    i{
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: 30px;
                    }
                    span{
                        padding-left: 5px;
                        font-size: 16px;
                        text-transform: capitalize;
                        font-family: var(--font-AvenirLTStd-Book);
                    }
                }
            }
        }
        .footer-information{
            position: relative;
            text-align: center;
            h2{
                font-size: 24px;
                text-transform: uppercase;
                color: var(--Cement);
            }
            ul{
                margin-top: 38px;
                display: grid;
                justify-content: center;
                li{
                    padding-bottom: 5px;
                    a{
                        color: var(--PineCone);
                        font-family: var(--font-AvenirLTStd-Book);
                    }
                    &:last-child{
                        padding-bottom: 0;
                    }
                }
            }
        }
        .footer-visit{
            position: relative;
            text-align: center;
            h2{
                font-size: 24px;
                font-family: var(--font-TrajanPro-Regular);
                text-transform: uppercase;
                color: var(--Cement);
            }
            .address{
                margin-top: 38px;
                width: 70%;
                margin-left: auto;
                margin-right: auto;
                p{
                    color: var(--PineCone);
                    text-align: left;
                    font-family: var(--font-AvenirLTStd-Book);
                }
                .contact{
                    li{
                        display: flex;
                        align-items: center;
                        padding: 10px 0;
                        color: var(--PineCone);
                        i{
                            width: 35px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            font-size: 30px;
                        }
                        span{
                            padding-left: 5px;
                            font-size: 16px;
                            font-family: var(--font-AvenirLTStd-Book);
                        }
                    }
                }
            }
        }
    }
    .footer-bottom{
        position: relative;
        margin: 0 !important;
        p{
            color: var(--Black);
            font-family: var(--font-AvenirLTStd-Book);
            font-size: 14px;
            a{
                color: var(--Black);
            }
        }
    }
}
@media screen and (max-width: 961px) {
    footer{
        .footer-top{
            .footer-information{
                ul{
                    margin-top: 10px;
                    li{
                        text-align: center;
                        padding-bottom: 10px;
                        &:last-child{
                            padding-bottom: 0;
                        }
                    }
                }
            }
            .footer-visit{
                .address{
                    margin: 0;
                    margin-top: 10px;
                    width: 100%;
                    p{
                        text-align: center;
                    }
                    ul{
                        display: grid;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}