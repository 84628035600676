.gallery-area{
    position: relative;
    overflow: hidden;
    .gallery-box{
        position: relative;
        width: 100%;
        height: auto;
        justify-items: center;
        align-items: center;
        display: grid;
        overflow: hidden;
        img {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            object-fit: cover;
            width: 100%;
            height: 364px;
        }
        &:hover{
            img{
                -webkit-transform: scale(1.3);
                transform: scale(1.3);
            }
        }
    }
}